/**Packages */
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import ContentBox from "../../../components/Elements/ContentBox";
import TwoColumnsLayout from "../../../components/Layout/TwoColumnsLayout";
import Row from "../../../components/Elements/Row";
import UserCardCompact from "../../../components/Elements/UserCardCompact";
import Button from "../../../components/Elements/Button";
import FloatingInput from "../../../components/Form/FloatingInput";
import Select from "../../../components/Form/Select";
import Form from "../../../components/Form/Form";
import TextLink from "../../../components/Elements/TextLink";

/**Helpers */
import { OTP_CASES } from "../../../helpers/constants";
import { getOTPProcedure } from "../../../helpers/handler";
import { default as rp } from "../../../providers/ResourceProvider";
import NavigationProvider from "../../../providers/NavigationProvider";

/**Redux */
import {
  LOADER_VISIBLE,
  LOADER_INVISIBLE,
} from "../../../redux/actionTypes/loaderActionsTypes";
import { setError } from "../../../redux/actions/errorActions";

/**CSS */
import "./style.css";

const BankAdvance = () => {
  const { t, i18n, ready } = useTranslation();

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setErrorMessage = (title, message) =>
    dispatch(setError(title, message));

  const user = useSelector((state) => state.user);
  const { selectedCard, token } = user;
  const { totalMonthUsage, id, fido = 0 } = selectedCard;

  // const refundablesIn = [-1, 6, 12, 18, 24];
  const refundablesIn = rp.getResource("bankAdvance.fidoRefundableIn");
  // const [formValidated, setFormValidated] = useState(false);
  // @Mari ho dovuto passare lo stato in questo modo poiché, come si vede dalla tavola 42 dell'XD, nel caso di fidoPos il rimborso è fissato a 6 mesi
  const fidoPosRefundableIn = rp.getResource("bankAdvance.fidoPosRefundableIn");
  const [refundableIn, setRefundableIn] = useState(
    rp.getResource("bankAdvance.refundableInEnabled")
      ? refundablesIn[0]
      : fidoPosRefundableIn
  );
  const [amount, setAmount] = useState(
    rp.getResource("bankAdvance.minRequestAmount")
  );
  const [amountErrorMessage, setAmountErrorMessage] = useState("");

  const onAmountHandler = (value) => {
    let newValue = parseFloat(value);

    if (newValue < rp.getResource("bankAdvance.minRequestAmount")) {
      newValue = rp.getResource("bankAdvance.minRequestAmount");
      setAmountErrorMessage(`L'importo minimo non può essere inferiore a ${rp.getResource("bankAdvance.minRequestAmount")}`);
    } else if (newValue > fido - totalMonthUsage) {
      newValue = fido - totalMonthUsage;
      setAmountErrorMessage(`L'importo minimo non può essere superiore a ${fido - totalMonthUsage}`);
    }
    else {
      setAmountErrorMessage(``);
    }
    setAmount(newValue);
  };

  const onChangeRefundableIn = (e) => {
    const newRefundableInValue = e?.target?.value;
    if (newRefundableInValue) {
      setRefundableIn(newRefundableInValue);
    }
  };

  // Make sure we disable all fields if user has no sufficient balance
  const [requestEnabled, setRequestEnabled] = useState(true);
  useEffect(() => {
    if (rp.getResource("bankAdvance.minRequestAmount") > (fido - totalMonthUsage)) {
      setRequestEnabled(false);
    }
  }, []);

  // const validateInput = () => {
  //   let validated = true;
  //   if (refundableIn === -1 || amount > { fido } || amount < rp.getResource("bankAdvance.minRequestAmount")) {
  //     validated = false;
  //   }

  //   setFormValidated(validated);
  // };

  const onSubmit = async () => {
    // if (formValidated) {
    //NavigationProvider.goTo(PAGES.USER_AREA.BANK_TRANSFER_REQUEST, { amount, refundableIn });
    activateLoader();

    const onSuccessHandler = async (data) => {
      NavigationProvider.goToOTPPage(
        {
          clickCase: OTP_CASES.BONIFICO,
          token,
          id,
          amount,
          refundableIn,
        },
        {
          token,
          id,
        }
      );
      deactivateLoader();
    };

    const onErrorHandler = (data) => {
      deactivateLoader();
      setErrorMessage(
        "Errore",
        "Errore durante la procedura di invio dell'otp, riprovare più tardi"
      );
    };

    await getOTPProcedure(token, id, "", onSuccessHandler, onErrorHandler);
    // }
  };

  return (
    <TwoColumnsLayout id="bank-advance-page">
      <Row gridSize="md" classList="space-below-md only-large-desktop">
        <UserCardCompact classList="text-left"></UserCardCompact>
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <PageTitle inner title={rp.getResource("bankAdvance.title")}>
          <p className="text-clear-formatting">
            {rp.getResource("bankAdvance.subtitle")}
          </p>
        </PageTitle>
      </Row>

      <Row gridSize="md" classList="space-below-sm">
        <ContentBox compact>
          <p>Importo</p>

          <Form noSubmit onSubmit={onSubmit}>
            <div className="inline-on-desktop">
              <FloatingInput
                name="advanceAmount"
                placeholder="Inserisci l'importo"
                validIf={[
                  `minValue--${rp.getResource("bankAdvance.minRequestAmount")}`,
                  `maxValue--${fido}`,
                  "notEmpty",
                ]}
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                onBlur={(e) => {
                  onAmountHandler(e.target.value);
                }}
                errorMessage={amountErrorMessage}
                withError={amountErrorMessage != ""}
                disabled={!requestEnabled}
              />

              {rp.getResource("bankAdvance.refundableInEnabled") ? (
                <Select
                  id="advanceDuration"
                  name="advanceDuration"
                  validIf={["selectNotEmpty"]}
                  onChange={onChangeRefundableIn}
                  value={refundableIn}
                  disabled={!requestEnabled}
                >
                  {refundablesIn.map((itm) => {
                    let optItm = <option value={itm}>{itm} mesi</option>;
                    if (itm === -1) {
                      optItm = <option value={-1}>Rimborsabile in</option>;
                    }

                    return optItm;
                  })}
                </Select>
              ) : (
                <FloatingInput
                  disabled
                  readonly
                  placeholder=""
                  value={`Rimborsabile in ${fidoPosRefundableIn} mesi`}
                />
              )}

              <div className="btn-container">
                <Button
                  round
                  text="Richiedi"
                  classList="btn-block"
                  type="submit"
                  isEnabled={requestEnabled}
                />
              </div>
            </div>

            {!requestEnabled &&
              <p className="text-color-primary">La tua disponibilità ({(fido - totalMonthUsage)}€) è inforiore all'importo minimo richiedibile ({rp.getResource("bankAdvance.minRequestAmount")}€)</p>
            }
          </Form>
        </ContentBox>
      </Row>

      <Row gridSize="md" classList="space-below-lg space-above-sm">
        <Button
          id="calculate-advance-button"
          round
          outline
          text="Calcola rata"
          isEnabled={refundableIn != -1 && amount != 0}
          classList="btn-block"
          onClick={() => {
            NavigationProvider.getCalculateRateLink();
          }}
        />
      </Row>

      <Row
        gridSize="md"
        classList="space-below-lg space-above-lg conditions-box"
      >
        <TextLink
          text="Condizioni di utilizzo"
          onClick={() => {
            NavigationProvider.openExternalLink(rp.getResource("externalLinks.transparency"));
          }}
        />
        <p className="text-indent">
          <small className="">
            {rp.getResource("bankAdvance.conditionsText")}
          </small>
        </p>
      </Row>
    </TwoColumnsLayout>
  );
};
export default BankAdvance;
