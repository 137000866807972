/**
 * Provider for all kinds of resources that are productType-dependent
 * Just ask for a specific resource defined by a path such as '<context>.<resouce>' and this provider
 *      will automatically give you the right resource based on any arbitrary condition we define here.
 *
 * ====================== EXAMPLE USAGE ======================
 *      1) first import like this:
 *      import { default as rp } from '...'
 *
 *      2) and then use it like this:
 *      as string interpolation
 *          ...text {rp.getResource('changeStandingEc.confirmText')} text...
 *      as dynamic HTML/Component property interpolation
 *          <img src={ rp.getResource('header.topLeftLogo') } />
 *      as show/hide toggle for entire HTML sections
 *          ...<html code>
 *          { rp.getResource('support.topLinks') && <Component_o_HTML> }
 *          <html code>...
 *      as iterator
 *          { rp.getResource('usefulNumbers.leftColumn').map((number) => { ... }) }
 *      @todo as strategy for api calls or handler functions
 *          ...js code...
 *           rp.getResource('movements.getLastTenMovements').then((results) => { ... })
 *          ...js code...
 */

/**
 * @todo full-fledged Singleton
 */
class ResourceProvider {
  /**
   * Utility function to set the Object map of the resources based on productType
   *
   * @private
   * @function _setResourcesMap
   * @todo allow arbitrary params length
   * @param {any} cartaBpp - resource for cartaBpp
   * @param {any} fidoPos - resource for fidoPos
   * @param {any} fidoAffari - resource for fidoAffari
   * @return {Object} object map of the resources
   */
  _setResourcesMap = (cartaBpp = "", fidoPos = "", fidoAffari = "") => {
    if (fidoPos == "") {
      return { cartaBpp: cartaBpp, fidoPos: cartaBpp, fidoAffari: cartaBpp };
    }
    return { cartaBpp, fidoPos, fidoAffari };
  };

  /**
   * This is the right place to add the resources map for the entire project
   * Use this pattern: <context>.<resource> (e.g. header.topLeftMenu, usefulNumbers.leftColumn, api.movements)
   *
   * @private
   */
  _resourcesMap = {
    header: {
      topLeftLogo: this._setResourcesMap(
        "images/logo-compass-banca-mobile.png",
        "images/logo-compass-banca-mobile.png",
        "images/logo-compass-banca-mobile.png"
      ),
      bankAdvanceTitle: this._setResourcesMap("bonifico in conto", "richiesta utilizzo", "richiesta utilizzo"),
    },
    products: {
      logo: this._setResourcesMap(
        "images/logo/cartaBancoPostaPiu.svg",
        "images/logo/fidobanco.svg",
        "images/logo/affari.svg",
      ),
    },
    userCard: {
      image: this._setResourcesMap(
        "images/cards/carta_bpp.svg",
        "images/cards/fido_pos.svg",
        "images/cards/fido_affari.svg"
      ),
      productCodeTitle: this._setResourcesMap("la tua carta di credito", "codice fido pos", "codice fido affari"),
      productTitle: this._setResourcesMap("la tua carta di credito", "fido bancoposta pos", "fido bancoposta affari"),
      expirationTitle: this._setResourcesMap("giorno scadenza", "giorno scadenza addebito", "giorno scadenza addebito"),
    },
    // used in ChangeStandingEc
    userCardTemplateTwo: {
      columnOne: this._setResourcesMap(true, false, false),
      columnTwo: this._setResourcesMap(true, true, true),
    },
    forgotPasswordConfirmationForgot: {
      title: this._setResourcesMap("Reimposta Password"),
      body: this._setResourcesMap(
        "La tua nuova password è stata impostata con successo. Per accedere subito all’area riservata "
      ),
    },
    forgotPasswordConfirmationActivate: {
      title: this._setResourcesMap("Account attivato"),
      body: this._setResourcesMap("Il tuo account è ora attivo. Per accedere subito all’area riservata "),
    },
    dashboard: {
      resumesSeciion: this._setResourcesMap(true, false, false),
    },
    movements: {
      detailsSection: this._setResourcesMap(true, false, false),
    },
    accountBalance: {
      formTitle: this._setResourcesMap("Cerca movimenti", "cerca", "cerca"),
    },
    bankAdvance: {
      title: this._setResourcesMap("BONIFICO IN CONTO", "RICHIESTA UTILIZZO", "RICHIESTA UTILIZZO"),
      subtitle: this._setResourcesMap(
        "Puoi utilizzare CartaBancoPostaPiù per trasferire la somma che desideri sul tuo conto corrente*",
        "Da qui puoi utilizzare il tuo Fido Pos per trasferire la somma che desideri su tuo conto corrente*",
        "Puoi utilizzare FidoBancoPosta Affari per trasferire la somma che desideri sul tuo conto corrente*"
      ),
      conditionsText: this._setResourcesMap(
        "* Importo minimo richiedibile pari a € 180. Importo max richiedibile pari al totale del fido disponibile. Per le condizioni economiche e contrattuali complete applicate all'operazione consulta il contratto in tuo possesso.",
        "* Importo minimo richiedibile pari a € 500. Importo max richiedibile pari al totale del fido disponibile. Per le condizioni economiche e contrattuali complete applicate all'operazione consulta il contratto in tuo possesso.",
        "Importo minimo richiedibile pari a € 500. Importo max richiedibile pari al totale del fido disponibile. Per le condizioni economiche e contrattuali complete applicate all'operazione consulta il contratto in tuo possesso.",
      ),
      refundableInEnabled: this._setResourcesMap(true, false, true),
      minRequestAmount: this._setResourcesMap(180, 500, 500),
      fidoPosRefundableIn: this._setResourcesMap(-1, 6, -1),
      fidoRefundableIn: this._setResourcesMap(
        [-1, 6, 12, 28, 24],
        [-1, 6],
        [-1, 24, 30, 36]
      ),
    },
    changeStandingEc: {
      confirmText: this._setResourcesMap(
        "Confermi di voler modificare la modalità di invio dell’estratto conto mensile della tua carta?",
        "Confermi di voler modificare la modalità di invio dell’estratto conto mensile del tuo fido?",
        "Confermi di voler modificare la modalità di invio dell’estratto conto mensile del tuo fido?"
      ),
      helperTextOne: this._setResourcesMap(
        "E' possibile richiedere la modifica della modalità di invio dell'estratto conto ogni mese. In caso di scelta della modalità cartacea potranno essere addebitati, per ciascun invio, i costi, se previsti, indicati nel contratto in tuo possesso. L'invio in formato elettronico è sempre gratuito.",
        "E' possibile richiedere la modifica della modalità di invio dell'estratto conto ogni mese. In caso di scelta della modalità cartacea potranno essere addebitati, per ciascun invio, i costi, se previsti, indicati nel contratto in tuo possesso. L'invio in formato elettronico è sempre gratuito.",
        "E' possibile richiedere la modifica della modalità di invio dell'estratto conto ogni mese. In caso di scelta della modalità cartacea potranno essere addebitati, per ciascun invio, i costi, se previsti, indicati nel contratto in tuo possesso. L'invio in formato elettronico è sempre gratuito."
      ),
      helperTextTwo: this._setResourcesMap(
        "All’interno dell’Area Clienti sono diponibili, per i 12 mesi successivi alla pubblicazione, gli estratti conto in formato digitale della tua carta di credito. Accedendo alla sezione “Mesi precedenti” è agevole stamparli e/o salvarli direttamente sul tuo computer. Puoi effettuare la tua scelta in modo indipendente e, in qualsiasi momento, ti sarà possibile cambiare idea e ripristinare la spedizione tramite servizio postale dell’estrato conto cartaceo.",
        "All’interno dell’Area Clienti sono disponibili, per i 12 mesi successivi alla pubblicazione, gli estratti conto in formato digitale del tuo fido. Accedendo alla sezione “Mesi precedenti” è agevole stamparli e/o salvarli direttamente sul tuo computer. Puoi effettuare la tua scelta in modo indipendente e, in qualsiasi momento, ti sarà possibile cambiare idea e ripristinare la spedizione tramite servizio postale dell’estratto conto cartaceo.",
        "All’interno dell’Area Clienti sono disponibili, per i 12 mesi successivi alla pubblicazione, gli estratti conto in formato digitale del tuo fido. Accedendo alla sezione “Mesi precedenti” è agevole stamparli e/o salvarli direttamente sul tuo computer. Puoi effettuare la tua scelta in modo indipendente e, in qualsiasi momento, ti sarà possibile cambiare idea e ripristinare la spedizione tramite servizio postale dell’estratto conto cartaceo."
      ),
    },
    smsActivation: {
      sendSmsForTransactionAmountText: this._setResourcesMap(
        "Desidero ricevere la notifica via SMS ogni volta che effettuo un acquisto di importo superiore a",
        "Desidero ricevere la notifica dei miei acquisti via SMS per importi superiori a",
        "Desidero ricevere la notifica dei miei acquisti via SMS per importi superiori a"
      ),
      sendSmsForTransactionAmountField: this._setResourcesMap(true, false, false),
      sendSmsForUpdatesText: this._setResourcesMap(
        "Desidero ricevere un SMS che alla chiusura di ogni estratto conto mi informi sul saldo, sull’ammontare della rata mensile e sulla disponibilità della carta.",
        "Desidero ricevere un sms mensile con le informazioni su saldo, rata mensile e disponibilità del mio Fido Pos",
        "Desidero ricevere un SMS mensile con le informazioni su saldo, rata mensile e disponibilità del mio Fido Affari"
      ),
    },
    support: {
      topLinks: this._setResourcesMap(true, false, false),
    },
    usefulNumbers: {
      leftColumn: this._setResourcesMap(
        [
          {
            text: `Servizio Clienti BancoPosta e Postepay (lun-sab 8-20) attivo per tutti i servizi (Carta BancoPosta Più e Fido)`,
            number: "06 45263322",
          },
          {
            text: "Attivazione carta (disponibile 24 ore su 24)",
            number: "800 21 67 40",
          },
          {
            text: "Attivazione carta dall’estero (lun-ven 9-19)",
            number: "+39 02 48 244 448",
          },
          {
            text: "Anticipo contanti (disponibile 24 ore su 24)",
            number: "06 45263322",
          },
          {
            text: "Denuncia sinistri",
            number: "199 770 009**",
          },
          {
            text: "Blocco Carta Bancoposta Più",
            number: "800 822 056",
          },
          {
            text: "Blocco Carta Bancoposta Più dall’estero",
            number: "+ 39 02 608 437 68",
          },
        ],
        [
          {
            text: `Se sei un professionista o una piccola impresa e hai bisogno di informazioni su un prodotto o un servizio di Poste Italiane, o di essere messo in contatto con un nostro commerciale, puoi chiamarci al numero`,
            number: "800 160 000",
          },
          {
            text: `<small>(per assistenza ai Clienti Business per prodotti finanziari e postali)</small>`,
            number: "",
          },
          {
            text: `Numero verde gratuito, da telefono fisso e mobile, attivo dalle ore 8 alle 20 dal lunedì al sabato.`,
            number: "",
          },
        ],
        [
          {
            text: `Se sei un professionista o una piccola impresa e hai bisogno di informazioni su un prodotto o un servizio di Poste Italiane, o di essere messo in contatto con un nostro commerciale, puoi chiamarci al numero`,
            number: "800 160 000",
          },
          {
            text: `<small>(per assistenza ai Clienti Business per prodotti finanziari e postali)</small>`,
            number: "",
          },
          {
            text: `Numero verde gratuito, da telefono fisso e mobile, attivo dalle ore 8 alle 20 dal lunedì al sabato.`,
            number: "",
          },
        ]
      ),
      helperText: this._setResourcesMap(false, false, false),
      conditionsText: this._setResourcesMap(false, false, false),
    },
    externalLinks: {
      transparency: this._setResourcesMap(
        "https://www.compass.it/share/pdfs/COM433G.pdf",
        "https://www.compass.it/share/pdfs/COM445.pdf",
        "https://www.compass.it/share/pdfs/COM446.pdf",
      )
    }
  };

  /**
   * Abstract the way we set a specific product, client, whatever and let this method give us the right match in any situation.
   * This way we can add other conditions to select the right prodcutType and/or we can decide to retrieve it from different sources (redux, context, sessionStorage, etc)
   *
   * @public
   * @todo return a constant defined in helpers/constants.js
   * @todo replace sessionStorage with redux store
   * @function getProductType
   * @return {string} the productType
   */
  getProductType = () => {
    return sessionStorage.getItem("productType") || "cartaBpp";
  };

  getProductName = () => {
    return this.getProductType() == "cartaBpp" || !this.getProductType() ? "CartaBancoPosta PIÙ" : this.getProductType() == "fidoPos" ? "Fido Pos BancoPosta" : "Fido Affari BancoPosta";
  };

  /**
   * Abstract the way we get a specific product, client, whatever and let this method set the right productType in any situation.
   * This way we can add other conditions to set the right prodcutType and/or we can decide to set it with different providers (redux, context, sessionStorage, etc)
   *
   * @public
   * @todo param should be passed as a constant defined in helpers/constants.js
   * @todo replace sessionStorage with redux store
   * @function setProductType
   * @param {string} productType - 'cartaBpp' || 'fidoPos' || 'fidoAffari'
   * @return {void}
   */
  setProductType = (productType) => {
    sessionStorage.setItem("productType", productType);
  };

  /**
   * Make sure we reset to default logo when logged out
   *
   * @public
   * @todo replace sessionStorage with redux store
   * @function resetProductType
   * @return {void}
   */
  resetProductType = () => {
    sessionStorage.removeItem("productType");
  };

  /**
   * It returns the right resource based on the internal productType
   *
   * @public
   * @todo make it work with deeper paths, now it works with just two levels
   * @function getResource
   * @param {string} templateString - e.g. <context>.<resource>
   * @return {any}
   */
  getResource = (templateString) => {
    const templatePath = templateString.split(".");
    return this._resourcesMap[templatePath[0]][templatePath[1]][this.getProductType()];
  };
}

export default new ResourceProvider();
