/**Packages */
import Cookies from "universal-cookie";

/**Helpers */
import { API_RESPONSE } from "../helpers/constants";
import { api } from "../api";
import { setToken, getToken, removeToken } from "./token";
import { removeUserData } from "./userData";

/**
 * Default callback in case of success
 * @param {*} result - result of the operation
 */
const onSuccessCallbackDefault = async (result) => {
    console.log("onSuccessCallbackDefault");
    console.log("result");
    console.log(result);
};
/**
 * Default callback in case of error
 * @param {*} result - result of the operation
 */
const onErrorCallbackDefault = (result) => {
    console.log("onErrorCallbackDefault");
    console.log("result");
    console.log(result);
};

/**
 *
 * @param {*} userId - username of the user
 * @param {*} userPassword  password of the user
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @param {*} setTokenOtpRedux
 * @returns The result of the operation
 */
export async function loginProcedureStep1(userId, userPassword, onSuccessCallback = onSuccessCallbackDefault, onErrorCallback = onErrorCallbackDefault, setTokenOtpRedux = null) {
    try {
        const result = await api.user.loginStep1(
            {
                userId,
                userPassword,
            },
            (result) => {
                const data = result.data;
                if (setTokenOtpRedux) {
                    const tokenOTP = data?.TokenOTP || data.tokenOTP;
                    setTokenOtpRedux(tokenOTP);
                }
                onSuccessCallback(data);
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}
/**
 *
 * @param {*} token - token to identify the user (and veriify he can do the operation)OTP
 * @param {*} userOTP - otp to authorize the alteration (used by the API)
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @param {*} setBaseUserData - redux action to set user data (not used anymore)
 * @returns The result of the operation
 */
export async function loginProcedureStep2(tokenOTP, userOTP, onSuccessCallback = onSuccessCallbackDefault, onErrorCallback = onErrorCallbackDefault, setBaseUserData = null) {
    try {
        if (await getToken()) {
            logoutProcedure();
        }

        const result = await api.user.loginStep2(
            {
                tokenOTP,
                userOTP,
            },
            async (result) => {
                const data = result.data;
                if (data?.utenti?.length > 0) {
                    if (setBaseUserData) {
                        setBaseUserData(data);
                    }
                    await onSuccessCallback(data);
                } else {
                    onErrorCallback();
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}
/**
 *
 * @param {*} username - username od the user
 * @param {*} email - email of the user
 * @param {*} cf - fiscal code of the user
 * @param {*} card_pan - pan of the id of the product
 * @param {*} bPrivacy - first privacy check flag
 * @param {*} bPrivacyCom - second privacy check flag
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @returns The result of the operation
 */
export async function signupProcedure(
    username,
    email,
    cf,
    card_pan,
    // idEmettitore,
    // password,
    bPrivacy,
    bPrivacyCom,
    onSuccessCallback = onSuccessCallbackDefault,
    onErrorCallback = onErrorCallbackDefault
) {
    try {
        const result = await api.user.signup(
            {
                username,
                email,
                cf,
                card_pan,
                // password,
                // idEmettitore,
                bPrivacy: bPrivacy || false,
                bPrivacyCom: bPrivacyCom || false,
            },
            async (result) => {
                const data = result?.data;
                if (data.result === API_RESPONSE.SUCCESS_CODE) {
                    await onSuccessCallback(data);
                } else {
                    onErrorCallback(data);
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}
/**
 *
 * @param {*} token - token to identify the user (and veriify he can do the operation)
 * @param {*} email - email od the new user
 * @param {*} card_id - id of the product
 * @param {*} nPratica - id of the pratique ( = id of the product )
 * @param {*} telefono - home number of the user
 * @param {*} cellulare - phone number of the user
 * @param {*} telefono2 - 2nd home number (used like recovery)
 * @param {*} indirizzo - address of the user of the ctual residence
 * @param {*} cap - cap od the actual residence
 * @param {*} localita - city of the actual residence
 * @param {*} provincia - province of the actual residence
 * @param {*} otp - otp to authorize the alteration (used by the API)
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @returns The result of the operation
 */
export async function updateUserInfoProcedure(
    token,
    email,
    card_id,
    nPratica,
    telefono,
    cellulare,
    telefono2,
    indirizzo,
    cap,
    localita,
    provincia,
    otp,
    onSuccessCallback = onSuccessCallbackDefault,
    onErrorCallback = onErrorCallbackDefault
) {
    try {
        const result = await api.user.updateUserInfo(
            {
                token,
                email,
                card_id,
                nPratica,
                telefono,
                cellulare,
                telefono2,
                indirizzo,
                cap,
                localita,
                provincia,
                otp,
            },
            async (result) => {
                const data = result?.data;
                if (data.result === API_RESPONSE.SUCCESS_CODE) {
                    await onSuccessCallback(data);
                } else {
                    onErrorCallback(data?.description);
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}

/**@Deprecated */
export async function getUserInfoProcedure(token, onSuccessCallback = onSuccessCallbackDefault, onErrorCallback = onErrorCallbackDefault, setUserInfoDataRedux = null) {
    try {
        const result = await api.user.getUserInfo(
            token,
            async (result) => {
                const data = result.data;
                if (data) {
                    /*
                        const cookies = new Cookies();
                        cookies.set('userInfo', userid, { path: '/' });
                        setUserProfile({ userid: userid });
                    */
                    if (setUserInfoDataRedux) {
                        setUserInfoDataRedux(data);
                    }
                    await onSuccessCallback(data);
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}
/**
 *
 * @param {*} token - token to identify the user (and veriify he can do the operation)
 * @param {*} month - month requested
 * @param {*} year - year requested
 * @param {*} card_id - id of the product
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @param {*} setMovements - redux action to set Movements
 * @returns The result of the request
 */
export async function getMovimentiProcedure(token, month = null, year = null, card_id, onSuccessCallback = onSuccessCallbackDefault, onErrorCallback = onErrorCallbackDefault, setMovements = null) {
    try {
        const result = await api.movement.getTransactions(
            token,
            month,
            year,
            card_id,
            async (result) => {
                const data = result.data;
                if (data) {
                    if (setMovements) {
                        setMovements(data, month, year);
                    }
                    await onSuccessCallback(data);
                } else {
                    onErrorCallback(data?.description);
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}
/**
 *
 * @param {*} token - token to identify the user (and veriify he can do the operation)
 * @param {*} month - month requested
 * @param {*} year - year requested
 * @param {*} card_id - id of the product
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @param {*} setResumes - redux action to set Resumes
 * @returns The result of the request
 */
export async function getResumesProcedure(token, month, year, card_id, onSuccessCallback = onSuccessCallbackDefault, onErrorCallback = onErrorCallbackDefault, setResumes = null) {
    try {
        const result = await api.movement.getResumes(
            token,
            month,
            year,
            card_id,
            async (result) => {
                const data = result?.data;
                if (data) {
                    if (setResumes) {
                        setResumes(data);
                    }
                    await onSuccessCallback(data);
                } else {
                    onErrorCallback(data?.description);
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}
/**
 *
 * @param {*} token - token to identify the user (and veriify he can do the operation)
 * @param {*} nCarta - id of the product
 * @param {*} nPratica - number of pratique (ignored)
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @returns The result of the request
 */
export async function getOTPProcedure(token, nCarta, nPratica, onSuccessCallback = onSuccessCallbackDefault, onErrorCallback = onErrorCallbackDefault) {
    try {
        const result = await api.operation.getOtp(
            {
                token,
                nCarta,
                nPratica,
            },
            async (result) => {
                const data = result?.data;
                if (data.result === API_RESPONSE.SUCCESS_CODE) {
                    await onSuccessCallback(data);
                } else {
                    onErrorCallback(data?.description);
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}
/**
 *
 * @param {*} token - token to identify the user (and veriify he can do the operation)
 * @param {*} nCarta - id of the product
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @returns The result of the request
 */
export async function getCardsUpdatedDataProcedure(token, nCarta, onSuccessCallback = onSuccessCallbackDefault, onErrorCallback = onErrorCallbackDefault) {
    try {
        const result = await api.operation.getCardsData(
            {
                token,
                nCarta,
            },
            async (result) => {
                const data = result?.data;
                if (data.result === API_RESPONSE.SUCCESS_CODE) {
                    await onSuccessCallback(data);
                } else {
                    onErrorCallback(data?.description);
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}
/**
 *
 * @param {*} token - token to identify the user (and veriify he can do the operation)
 * @param {*} card_id - id of the product
 * @param {*} amount - amount requested
 * @param {*} refundable_in - month that amount will be refunded
 * @param {*} otp - otp to authorize the alteration (used by the API)
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @returns The result of the operation
 */
export async function transferProcedure(token, card_id, amount, refundable_in, otp, onSuccessCallback = onSuccessCallbackDefault, onErrorCallback = onErrorCallbackDefault) {
    try {
        const result = await api.operation.transfer(
            {
                token,
                card_id,
                amount,
                refundable_in,
                otp,
            },
            async (result) => {
                const data = result?.data;
                if (data.result === API_RESPONSE.SUCCESS_CODE) {
                    await onSuccessCallback(data);
                } else {
                    onErrorCallback(data?.description);
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}
/**
 *
 * @param {*} token - token to identify the user (and veriify he can do the operation)
 * @param {*} card_id - id of the product
 * @param {*} mode - account balance mode selected (I = Internet, C = Cartaceo)
 * @param {*} email_cc_receiver - if mode === C this is the email who will receive the account balance otherwise is ignored
 * @param {*} otp - otp to authorize the alteration (used by the API)
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @returns The result of the operation
 */
export async function setECMethodProcedure(token, card_id, mode, email_cc_receiver, otp, onSuccessCallback = onSuccessCallbackDefault, onErrorCallback = onErrorCallbackDefault) {
    try {
        const result = await api.operation.setCCMethod(
            {
                token,
                card_id,
                mode,
                email_cc_receiver,
                otp,
            },
            async (result) => {
                const data = result?.data;
                if (data.result === API_RESPONSE.SUCCESS_CODE) {
                    await onSuccessCallback(data);
                } else {
                    onErrorCallback(data?.description);
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}
/**
 *
 * @param {*} token - token to identify the user (and veriify he can do the operation)
 * @param {*} card_id - id of the product
 * @param {*} phone_number - number who receive the SMS
 * @param {*} min_amount - min amount from which SMS will send
 * @param {*} alertNuovaAutorizzazione - flag to authorize or not to send SMS on movements where amount is bugger tham min_amount
 * @param {*} alertCalcoloNuovoEC - flag to authorize or not to send SMS on new account balance generated
 * @param {*} otp - otp to authorize the alteration (used by the API)
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @returns The result of the operation
 */
export async function setSMSActivationProcedure(
    token,
    card_id,
    phone_number,
    min_amount,
    alertNuovaAutorizzazione,
    alertCalcoloNuovoEC,
    otp,
    onSuccessCallback = onSuccessCallbackDefault,
    onErrorCallback = onErrorCallbackDefault
) {
    try {
        const result = await api.operation.SMSActivation(
            {
                token,
                card_id,
                phone_number,
                min_amount,
                alertNuovaAutorizzazione,
                alertCalcoloNuovoEC,
                otp,
            },
            async (result) => {
                const data = result?.data;
                if (data.result === API_RESPONSE.SUCCESS_CODE) {
                    await onSuccessCallback(data);
                } else {
                    onErrorCallback(data?.description);
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}
/**
 *
 * @param {*} token - token to identify the user (and veriify he can do the operation)
 * @param {*} email_receiver - email who will receive the response
 * @param {*} topic - Subject of the request
 * @param {*} text - Text of the request
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @returns The result of the operation
 */
export async function customCareProcedure(
    token,
    email_receiver,
    topic,
    text,
    //otp,
    onSuccessCallback = onSuccessCallbackDefault,
    onErrorCallback = onErrorCallbackDefault
) {
    try {
        const result = await api.customCare.customCare(
            {
                token,
                email_receiver,
                topic,
                text,
                //otp,
            },
            async (result) => {
                const data = result?.data;
                if (data.result === API_RESPONSE.SUCCESS_CODE) {
                    await onSuccessCallback(data);
                } else {
                    onErrorCallback(data?.description);
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}
/**
 *
 * @param {*} token - token to identify the user (and veriify he can do the operation) - token to identify the user (and veriify he can do the operation)
 * @param {*} username - username of the user -> not used anymore
 * @param {*} oldPassword - old password of the account
 * @param {*} newPassword - new password of the account
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @returns The result of the api (editPassword)
 */
export async function editPasswordProcedure(token, username, oldPassword, newPassword, onSuccessCallback = onSuccessCallbackDefault, onErrorCallback = onErrorCallbackDefault) {
    try {
        const result = await api.user.editPassword(
            {
                token,
                //username,
                oldPassword,
                password: newPassword,
            },
            async (result) => {
                const data = result?.data;
                if (data.result === API_RESPONSE.SUCCESS_CODE) {
                    await onSuccessCallback(data);
                } else {
                    onErrorCallback(data?.description);
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}
/**
 *
 * @param {*} username - username od the user
 * @param {*} email - email of the user
 * @param {*} cf - fiscal code of the user
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @returns The result of the api (1^ step of the recoveryPassword)
 */
export async function recoveryPasswordProcedure(username, email, cf, onSuccessCallback = onSuccessCallbackDefault, onErrorCallback = onErrorCallbackDefault) {
    try {
        const result = await api.user.recoveryPassword(
            {
                username,
                email,
                cf,
            },
            async (result) => {
                const data = result?.data;
                if (data.result === API_RESPONSE.SUCCESS_CODE) {
                    await onSuccessCallback(data);
                } else {
                    onErrorCallback(data?.description);
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
}
/**
 *
 * @param {*} guid - unique key used by the api to complete the process
 * @param {*} username - username of the user
 * @param {*} newPassword - password to set
 * @param {*} onSuccessCallback - callback to use in case api return success code
 * @param {*} onErrorCallback - callback to use in case api return error codes
 * @returns The result of the api (2^ step of the recoveryPassword)
 */
export const recoveryPasswordStep2Procedure = async (guid, username, newPassword, onSuccessCallback = onSuccessCallbackDefault, onErrorCallback = onErrorCallbackDefault) => {
    try {
        const result = await api.user.recoveryPasswordStep2(
            {
                username,
                magickey: guid,
                password: newPassword,
            },
            async (result) => {
                const data = result?.data;
                if (data.result === API_RESPONSE.SUCCESS_CODE) {
                    await onSuccessCallback(data);
                } else {
                    onErrorCallback(data?.description);
                }
            },
            onErrorCallback
        );

        return result;
    } catch (err) {
        console.log("err");
        console.log(err);
        return null;
    }
};

/**
 * The method remove the token from the sessionStorage
 */
export const logoutProcedure = async () => {
    removeToken();
    removeUserData();
};
